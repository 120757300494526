
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { toIntlNumberFormat } from '@/utils'
import axios from '@/plugins/axios'
import moment from 'moment'


enum PAYMENT_STATUS {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    ERROR = 'error',
}

export default defineComponent({
    setup(){
        const store = useStore()
        const toast = useToast()
        const dialog = useConfirm()

        const serviciosVirtuales = ref<any[]>([])
        const servicioSeleccionado = ref<any>(null)
        const turnoRecepcionado = ref<any>(null)
        const turnoAPagar = ref<any>(null)
        const timer = ref<any>(null)
        const counterAndTime = ref<any>(null)
        
        const getServiciosVirtuales = async () => {
            try {
                const filters = {
                    tipo: 'S',
                    demanda_virtual:true,
                }

                const includes = [{
                    model:'prestacion',
                    required:true,
                }]

                const { data } = await axios.post('especialidades-servicios/get-all',{
                    filters:JSON.stringify(filters),
                    includes:JSON.stringify(includes)
                })

                serviciosVirtuales.value = data.servEsp

            } catch (error) {
                console.log(error);
            }
        }

        const getTurnoRecepcionado = async () => {
            const idpaciente = store.state.pacienteLogueado?.id;
            const fechaInicio = moment().set({hour:0,minute:0,second:0,millisecond:0})
            const fechaFin = moment().set({hour:23,minute:59,second:59,millisecond:0})
            try {
                const includes = encodeURIComponent(
                    JSON.stringify([{
                        model:'turno',
                        required:true,
                        include:[
                        {
                            model:'servicioEspecialidad',
                            attributes:['demanda_virtual'],
                            where:{
                                demanda_virtual:true
                            },
                            required:true,
                        },
                        {
                            model:'turnoVideoconsultas',
                            required:true,
                        },
                        {
                            model:'paciente',
                            required:true,
                            where:{
                                id:idpaciente
                            },
                        },
                        {
                            model:'cobertura'
                        },
                        {
                            model:'planCobertura'
                        },
                        {
                            model: 'tipoTurno'
                        }]
                    }])
                );
                const filters = encodeURIComponent(JSON.stringify({
                    estado:'En espera',
                    recepcion:{'between':[fechaInicio.toISOString(), fechaFin.toISOString()]},
                }));

                const order = encodeURIComponent(JSON.stringify([['turno', 'hora', 'ASC'], ['turno', 'fecha', 'ASC']]));

                const { data } = await axios.get(`/turnos-presentes?includes=${includes}&filters=${filters}&page=1&pageSize=1&order=${order}`);
                turnoRecepcionado.value = data.rows[0] || null

                if(turnoRecepcionado.value && !turnoRecepcionado.value.idmedicosespecialidad){
                    if(!counterAndTime.value){
                        getCounterAndTime()
                    }
                    timer.value = setTimeout(() => {
                        getTurnoRecepcionado()
                        getCounterAndTime()
                    }, 2000)
                    return;
                }


            } catch (error) {
                console.log(error);
            }
        }

        const getCounterAndTime = async () => {
            try {
                if(!turnoRecepcionado.value){
                    return;
                }

                const { data } = await axios.get(`/turno/get-counter-and-time`,{
                    params:{
                        idrecepcion:turnoRecepcionado.value.id,
                        idespecialidad:turnoRecepcionado.value.turno.idservicioespecialidad,
                    }
                })
                const { count, tiempoPromedio:{hours, minutes, seconds, milliseconds}} = data;
                const multiplier = count || 1;
                const tiempo = moment().set({
                    hours:hours * multiplier, 
                    minutes:minutes * multiplier,
                    seconds:seconds * multiplier,
                    milliseconds:milliseconds * multiplier
                }).diff(moment().set({hours:0, minutes:0, seconds:0, milliseconds:0}),'milliseconds');
                const time = moment.duration(tiempo,'milliseconds').locale('es').humanize()

                counterAndTime.value = {
                    count,
                    time,
                }
            } catch (error:any) {
                toast.add({
                    life: 3000,
                    severity:'error',
                    summary:'Atencion',
                    detail:error.response?.data?.message || error.message || 'Error al obtener el tiempo de espera',
                });
            }
        }

        const getTurnoAPagar = async () => {
            try {
                const { id } = store.state.pacienteLogueado;
                const fechaInicio = moment().set({hour:0,minute:0,second:0,millisecond:0})
                const fechaFin = moment().set({hour:23,minute:59,second:59,millisecond:0})
                const filters = {
                    idpaciente: id,
                    fecha: {
                        between: [fechaInicio.toISOString(), fechaFin.toISOString()],
                    },
                    presente:false,
                }

                const includes = [{
                    model:'servicioEspecialidad',
                    required:true,
                    where:{
                        demanda_virtual:true
                    },
                },{
                    model:'turnoPrestacion',
                    required:true,   
                },
                {
                    model:'turnoVideoconsultas',
                    required:true,
                },
                {
                    model:'turnoRecepcionado',
                    required:false,
                }]

                const { data } = await axios.get('turnos/get-all',{
                    params:{
                        filters:JSON.stringify(filters),
                        includes:JSON.stringify(includes),
                        withPaymentInfo:true,
                    }
                })

                const dataFiltered = data.rows.filter((turno:any) => turno.turnoRecepcionados?.[0]?.estado !== 'Llamado')

                
                turnoAPagar.value = dataFiltered[0] || null

                if(!turnoAPagar.value){
                    return;
                }

                if(turnoAPagar.value?.metadata?.paymentInfo?.status === PAYMENT_STATUS.APPROVED || !turnoAPagar.value?.metadata?.mercadoPagoData){
                    recepcionarTurno()
                    return;
                }

                if(turnoAPagar.value){
                    setTimer()
                }

            } catch (error) {
                console.log(error)
            }
        }

        const handleSolicitarCita = () => {
            if(!serviciosVirtuales.value.length){
                toast.add({severity:'error', summary:'Atencion', detail:'No hay servicios disponibles'})
                return;
            }
            if(!servicioSeleccionado.value){
                toast.add({severity:'error', summary:'Atencion', detail:'Seleccione un servicio'})
                return;
            }
            const { id } = store.state.pacienteLogueado;
            const { cliente } = store.state.configuracionPortal
            const turno = {
                idpaciente: id,
                idservicioespecialidad: servicioSeleccionado.value?.id,
                idusuario: cliente.usuarios[0]?.id,
            }
            solicitarCita(turno)
        }

        const solicitarCita = async (turno:any) => {
            try {
                const turnoResponse = await axios.post('/turnos/create-demanda-virtual',turno)
                if(!turnoResponse.data.metadata?.paymentInfo){
                    recepcionarTurno(turnoResponse.data)
                    return;
                }
                turnoAPagar.value = turnoResponse.data
            } catch (error:any) {
                toast.add({
                    severity:'error', 
                    summary:'Atencion', 
                    detail:error.response?.data?.message || error.message || 'Error al solicitar la cita'}
                )
                console.log(error);
            }
        }

        const pagarTurno = () => {
            const a = document.createElement('a')
            a.href = turnoAPagar.value.metadata?.mercadoPagoData?.link
            a.target = '_blank'
            a.click()
            setTimer()
        }

        const cancelarCita = async () => {
            const response = await openConfirmDialog('¿Está seguro que desea cancelar la cita?')
            if(response){
                try {
                    await axios.delete(`/turnos/${turnoAPagar.value?.id || turnoRecepcionado.value?.idturno}`)
                    getTurnoAPagar()
                    turnoRecepcionado.value = null
                } catch (error) {
                    console.log(error);
                }
            }
        }

        const openConfirmDialog = (text:string) => {
            return new Promise((resolve,reject) => {
                dialog.require({
                    message: text,
                    header: 'Confirmar',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => resolve(true),
                    reject: () => resolve(false)
                });
            })
        }

        const setTimer = () => {
            setTimeout(() => {
                clearInterval(timer.value)
                timer.value = setInterval(() => {
                    getPaymenData()
                }, 2000)
            }, 3000);
        }

        const getPaymenData = async () => {
            try {
                const { data } = await axios.get(`/turnos/get-payment-data/${turnoAPagar.value.id}`)
                if(data.status === PAYMENT_STATUS.APPROVED){
                    recepcionarTurno()
                    clearInterval(timer.value)
                }
                console.log(data);
            } catch (error) {
                console.log(error);
            }
        }

        const recepcionarTurno = async (turnoARecepcionar?:any) => {
            try {
                const { id , metadata, ...turno} = turnoARecepcionar || turnoAPagar.value
                await axios.get(`/turnos/recepcionar-demanda-virtual/${id}`,{
                    id,
                    ...turno,
                    presente:true,
                })
                clearInterval(timer.value)
                await getTurnoRecepcionado()
                turnoAPagar.value = null
            } catch (error) {
                console.log(error);
            }
        }

        const urlLLamadaVirtual = computed(() => {
            const urlbase = store.getters.getUrlBase
            if(!turnoRecepcionado.value?.turno?.turnoVideoConsulta || !turnoRecepcionado.value?.turno?.idmedicosespecialidad){
                return ''
            }
            
            return `${urlbase}/room/${turnoRecepcionado.value.turno.turnoVideoConsulta.idsala}`
        })

        const sexoPaciente = computed(() => store.state.pacienteLogueado.sexo)

        onMounted(()=>{
            getServiciosVirtuales()
            getTurnoRecepcionado()
            getTurnoAPagar()
        })

        onUnmounted(()=>{
            clearInterval(timer.value)
        })

        return{
            counterAndTime,
            turnoAPagar,
            serviciosVirtuales,
            turnoRecepcionado,
            servicioSeleccionado,
            urlLLamadaVirtual,
            sexoPaciente,
            handleSolicitarCita,
            recepcionarTurno,
            pagarTurno,
            cancelarCita,
            toIntlNumberFormat,
        }
    }
})
