import { createStore } from 'vuex'


export default createStore({
  state: {
    isAutenticated:false,
    configuracionPortal: null as any,
    pacienteLogueado:null as any,
    pacientePadre:null as any,
    pacientesRelacionados:null as any,
    isLoading:false,
    embed: false,
  },
  mutations: {
    SET_AUTH (state, {pacienteLogueado,bool}){
      state.isAutenticated = bool
      state.pacienteLogueado = pacienteLogueado;
      state.pacientePadre = pacienteLogueado;
      state.pacientesRelacionados = pacienteLogueado?.pacientesRelacionados || null;
    },
    SET_CONFIG(state , config){
      state.configuracionPortal = config;
    },
    SET_LOAD(state , bool){
      state.isLoading = bool;
    },
    SET_PACIENTE(state, paciente){
      
      const index = state.pacientesRelacionados.findIndex((pacienteRelacionado:any) => pacienteRelacionado.id === paciente.id )
      const pacienteActual = state.pacienteLogueado;

      if(index === -1){
        return;
      }
      
      state.pacientesRelacionados.splice(index,1,{...pacienteActual});
      state.pacienteLogueado = {...paciente};
    },
    SET_EMBED(state,bool){
      state.embed = bool;
    }
  },
  actions: {
    autenticar({commit},datos){
      commit('SET_AUTH',{pacienteLogueado:datos,bool:true});
    },
    cerrarSesion({commit}){
      commit('SET_AUTH',{pacienteLogueado:null,bool:true});
    },
    cambiarPaciente({commit},paciente){
      commit('SET_PACIENTE',paciente);
    },
    setEmbed({commit},bool){
      commit('SET_EMBED',bool);
    }
  },
  getters:{
    coberturaPermitida: (state) => {
      
      const coberturas = state.configuracionPortal.parametros.coberturas;
      const paciente = state.pacienteLogueado;
      const esValido = coberturas.top.some((cob:any) => cob === paciente.idcobertura) 
              || coberturas.children.some((plan:any) => plan === paciente.idplancobertura);


      return esValido;
    },
    coberturaParticular: (state) => {
      return state.configuracionPortal.cobertura;
    },
    getUrlBase(){
      const host = window.location.host 
      const subdomain = host.split('.')[0]
      if(subdomain === 'testing-portal'){
          return 'https://testing.notifications.globalapp.ar'
      } else if(subdomain === 'portal') {
          return 'https://notifications.vs.globalapp.ar'
      } else {
          return 'http://localhost:4000/views'
      }
  }
  },
  modules: {
  }
})
