
import {defineComponent, onMounted} from 'vue'
import axios from '@/plugins/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router'


export default defineComponent({
    setup(){
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const pedirDatosCliente = async () => {
            try{
                const { cliente } = route.params;
                
                const result = await axios.get(`clientes/configuracion-portal-publico/${cliente}`)
                setTimeout(()=>{

                    if(result.data){
                        if(!result.data.activo){
                            router.push({name:'Inactivo', params:{cliente}})
                            return;
                        }
                        localStorage.setItem('cliente', `${cliente}`);
                        window.document.title = "Portal " + result.data.cliente.descripcion
                        store.commit('SET_CONFIG', result.data);
                        return router.push(`/${cliente}/login`);
                    }

                    return router.push('/404')

                },1500)
                
                
            }catch(err){
                console.log(err);
                router.push('/404')
            }
            }

            onMounted(() => {
                pedirDatosCliente();
            })

        return {

        }
    }
})
